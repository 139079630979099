<template>
  <div id="app">
    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>

@font-face {
  font-family: "Knockout";
  src: url("./assets/fonts/konockout.OTF");
}

@font-face {
  font-family: "KnockoutMedium";
  src: url("./assets/fonts/KnockoutMedium.ttf");
}

@font-face {
  font-family: "KNOCKOUT-HTF49-LITEWEIGHT";
  src: url("./assets/fonts/KNOCKOUT-HTF49-LITEWEIGHT.OTF");
}

@font-face {
  font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
  src: url("./assets/fonts/KNOCKOUT-HTF53-HEVIWEIGHT.OTF");
}

@font-face {
  font-family: "klavika bold";
  src: url("./assets/fonts/klavika-bold.otf");
}

@font-face {
  font-family: "klavika light";
  src: url("./assets/fonts/klavika-light.otf");
}

@font-face {
  font-family: "klavika medium";
  src: url("./assets/fonts/klavika-medium.otf");
}

@font-face {
  font-family: "KNOCKOUT-HTF70";
  src: url("./assets/fonts/KNOCKOUT-HTF70-FULLWELTERWT.OTF");
}

@font-face {
  font-family: "InstrumentSansRegular";
  src: url("./assets/fonts/InstrumentSans-Regular.ttf");
}

@import url("./assets/css/main.css");
.loading_global {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #00abc8;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
        transform: rotate(0deg);
  }
  100% {
        transform: rotate(360deg);
  }
} 

</style>