<template>
    <div>
      <BannerHome />

      <MinhaColecao /> 

      <Indique />

      <Beneficios />

      <VemPorAi />

      <FiqueDeOlho />

      <Faq />

      <TheFooter />

    </div>
</template>

<script>
import Faq from "@/components/Faq.vue";
import FiqueDeOlho from "@/components/FiqueDeOlho.vue";
import VemPorAi from "@/components/VemPorAi.vue";
import MinhaColecao from "@/components/MinhaColecao.vue";
import BannerHome from "@/components/BannerHome.vue";
import Indique from "@/components/Indique.vue";
import TheFooter from "@/components/Base/TheFooter.vue";
import Beneficios from "@/views/Beneficios.vue";
export default {
 name: "Home",
 components: {
    BannerHome,
    TheFooter,
    MinhaColecao,
    FiqueDeOlho,
    Indique,
    Beneficios,
    VemPorAi,
    Faq
  },

};
</script>
