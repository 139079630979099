<template>
    <div class="secao-faq-2">
        <b-container>
            <b-row class="faq-t">
                <b-col class="conteudo-tab" cols="12" lg="6"> 
                    <h3 class="txtduvidas-2">TUDO SOBRE  <br>O PORTAL</h3>
                </b-col>
                <b-col cols="12" lg="6">
                    <div class="conteudo-faq-2">
                        <div class="mb-4" v-for="(faq, index) in faqs" :key="index">
                            <button 
                                class="pergunta" 
                                @click="toggleFaq(index)"
                            >
                                <img src="@/assets/img/seta-direita.png" alt="Icone de seta"/>
                                {{ faq.pergunta }}
                            </button>
                            <b-collapse :visible="activeIndex === index">
                                <b-card class="resposta2"> 
                                    {{ faq.resposta }}
                                </b-card>
                            </b-collapse>
                        </div>
                    </div>                                   
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: null, // Armazena qual pergunta está aberta
            faqs: [
                { pergunta: "O que é o portal Legionários?", resposta: "O portal Legionários é um programa de benefícios exclusivo da Stanley, onde você pode acessar descontos, ingressos para eventos, experiências especiais e muito mais." },
                { pergunta: "Como faço para me cadastrar no portal Legionários?", resposta: "Basta acessar esse link e preencher o formulário de inscrição com seus dados. É simples, rápido e de graça!" },
                { pergunta: "Quais tipos de benefícios estão disponíveis no portal?", resposta: "Os benefícios incluem promoções e cupons de desconto exclusivos, ingressos para shows e eventos, brindes especiais, acesso antecipado a lançamentos e muito mais que está por vir!" },
                { pergunta: "Como posso resgatar os benefícios disponíveis?", resposta: "Para resgatar um benefício, faça login na sua conta do portal Legionários, acesse a seção de benefícios e clique no item desejado." },
                { pergunta: "Preciso pagar para participar do portal Legionários?", resposta: "Não, a participação no portal Legionários é gratuita. Basta se cadastrar para aproveitar os benefícios disponíveis." }
            ]
        };
    },
    methods: {
        toggleFaq(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        }
    }
};
</script>


<style>

.secao-faq-2 {
    background-color: #708573;
    padding: 70px 0px 0px 0px;
}

.txtduvidas-2 {
    font-size: 42px;
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    letter-spacing: 2.31px;
    color: #fff;
    width: 500px;
}

.txtesclarece {
    color: #FFFFFF;
    letter-spacing: 1.71px;
    font-family: "klavika bold";
    font-size: 18px;
}

.pergunta {
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 20px;
    font-family: "klavika bold";
    letter-spacing: 1.9px;
    margin-right: 20px;
}

.resposta2 {
    letter-spacing: 1px;
    color: #fff;
    font-size: 20px;
    font-family: "klavika light" !important;
    background-color: transparent;
    border: none;
    margin-bottom: 30px;
}

.resposta2 a {
    color: white !important;
}

.card {
    border: none !important;
}

.resposta2 .card-body {
    color: white;
    border: none !important;
    background: #708573;
    letter-spacing: 1.5px;
    font-family: "klavika light" !important;
    padding: 0px 30px !important;
}

@media (min-width: 991px) {
    .mob {
        display: none;
    }
}

@media (max-width: 990px) {

    .conteudo-faq-2 {
        margin-top: 0px; 
    }

    .txtduvidas-2 {
        font-size: 32px;
        line-height: 34px;
        margin-top: 5px;
        width: 100%;
        margin-bottom: 30px;
    }

    .txtesclarece {
         margin-bottom: 20px;
    }

    .pergunta, .resposta2 {
        font-size: 16px;
        line-height: 21px;
    }

    .secao-faq-2 {
        padding: 30px 0px 30px 0px;
    }
}

</style>