<template>

        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>

       <div class="bgextrato" v-else>
         <div>
            <section class="container">  
            
                <MenuMobile />

                <div class="loading_global" v-if="loading">
                    <span class="loader"></span>
                </div> 
                
                <div v-else>
                    <!--<div class="container voltar">
                        <button type="button" class="anterior" @click="voltar">
                            <img
                                src="@/assets/img/leftwhite.png"
                                alt="icone seta para esquerda"
                                class="seta"
                            />
                        </button>
                        <p><span>Meu extrato </span> <br />Home</p>
                    </div>  -->

                    <b-container>
                      <b-row>
                         <b-col cols="12" md="6">
                            <div class="conteudo-extrato">
                              <h2 class="th-extrato">MEU EXTRATO</h2>
                              <!--<div>
                                  <div class="d-flex box-select">
                                    <div>
                                      <label>MÊS</label><br>
                                      <select name="select" ref="mes" class="mes" v-model="mesSelecionado"> 
                                          <option value="01">JANEIRO</option>
                                          <option value="02">FEVEREIRO</option>
                                          <option value="03">MARÇO</option>
                                          <option value="04">ABRIL</option>
                                          <option value="05">MAIO</option>
                                          <option value="06">JUNHO</option>
                                          <option value="07">JULHO</option>
                                          <option value="08">AGOSTO</option>
                                          <option value="09">SETEMBRO</option>
                                          <option value="10">OUTUBRO</option>
                                          <option value="11">NOVEMBRO</option>
                                          <option value="12">DEZEMBRO</option>
                                        </select> 
                                    </div>

                                    <div>
                                      <label>ANO</label><br>
                                      <select ref="ano" name="select" class="ano" v-model="anoSelecionado"> 
                                        <option value="2024">2024</option>
                                        <option value="2023">2023</option>
                                        <option value="2023">2022</option>
                                        <option value="2023">2021</option>
                                        <option value="2023">2020</option>
                                      </select> 
                                    </div>
                                    <button @click="verTodos" class="todos">VER TODOS</button>
                                  </div>
                              </div>-->
                              <div class="lista-extrato">
                                <div v-if="extratosFiltrados.length == 0">
                                  <p class="msg-resultado">Nenhum resultado foi encontrado</p>
                                </div>
                                <div class="info-extrato" v-for="(extrato, index) in extratosFiltrados" :key="index">
                                    <div class="d-flex">
                                      <img v-if="extrato.motivo == 'Compra'"
                                        src="@/assets/img/bear-stanley.svg"
                                        alt="icone extrato"
                                        class="icone-extrato"
                                      />

                                      <img v-if="extrato.motivo == 'Indicação'"
                                        src="@/assets/img/compartilhar.svg"
                                        alt="icone extrato"
                                        class="icone-extrato"
                                      />

                                      <img v-if="extrato.motivo == 'Enriquecimento de Perfil'"
                                        src="@/assets/img/usuario.svg"
                                        alt="icone extrato"
                                        class="icone-extrato"
                                      />

                                      <img v-if="extrato.motivo == 'Resgate de Benefício'"
                                        src="@/assets/img/bear-stanley.svg"
                                        alt="icone extrato"
                                        class="icone-extrato"
                                      />

                                      <div class="dadosPontos">
                                        <h3>{{ extrato.motivo }}</h3>

                                        <p v-if="extrato.motivo == 'Indicação'" class="data-beneficio"> {{ extrato.indicado }} </p>

                                        <p class="nome-beneficio" v-if="extrato.beneficio && extrato.motivo == 'Resgate de Benefício'"> {{extrato.beneficio.nome}} </p>

                                        <p v-if="!extrato.usoCupom" class="data-beneficio"> {{ formatarData(extrato.dataTransacao) }} 
                                          <span class="txt-disponivel" v-if="!extrato.usoCupom && extrato.motivo == 'Resgate de Benefício'"> - Benefício resgatado</span>
                                        </p> 

                                        <p class="data-beneficio" v-else-if="extrato.motivo == 'Resgate de Benefício'"> {{ formatarData(extrato.dataUsoCupom) }} 
                                          <span class="txt-beneficio-utilizado"> - Benefício utilizado</span>
                                        </p> 
          
                                      </div>
                                      
                                    </div>
                                    <div class="d-flex dataExtrato">
                                        <div>
                                          <p v-if="extrato.motivo == 'Resgate de Benefício'" class="beneficio">{{ extrato.valor }}</p>
                                          <p v-else-if="extrato.motivo == 'Indicação'" class="beneficio-indicacao">+{{ extrato.valor }}</p>
                                        </div>
                                      <img
                                          @click="openModal(extrato)"
                                          src="@/assets/img/setabranca.png"
                                          alt="icone seta para direita"
                                        />
                                    </div>
                                </div>
                                
                              </div>
                            </div>
                         </b-col>
                      </b-row>

                    </b-container>

                </div>
            </section>
            
        </div>

        <div>
         <b-modal id="modal-extrato" ref="modalInfo" no-footer>
            <div>
                 <div >

                     <img v-if="dadosExtrato.motivo == 'Indicação'" 
                        src="@/assets/img/img-indicacao.png"
                        alt="Imagem de indicação"

                    />

                    <img v-else-if="dadosExtrato.motivo == 'Resgate de Benefício'"
                        :src="dadosExtrato.beneficio.urlImagem"
                        alt="Imagem de benefício resgatado"
                     />

                     <img v-else-if="dadosExtrato.motivo == 'Enriquecimento de Perfil'"
                        src="@/assets/img/usuario.svg"
                        alt="Imagem de enriquecimento de perfil"
                        class="outros"
                     />

                     <img v-else
                        src="@/assets/img/bear-stanley.svg"
                        alt="Imagem de bear stanley"
                        class="outros"
                     />

                 </div>

                 <div>
                  <h3 v-if="dadosExtrato.motivo != 'Resgate de Benefício'">{{ dadosExtrato.motivo }}</h3>
                  <p v-if="dadosExtrato.indicado" class="p-indicado"><strong>Indicado:</strong> <br><span class="email-indicado">{{ dadosExtrato.indicado }}</span></p>
                 </div>
                
                <div v-if="dadosExtrato.motivo == 'Resgate de Benefício'">
                  <p class="p-resgate"><strong>{{ dadosExtrato.beneficio.nome }}</strong> </p>
                  
                  <p v-if="!dadosExtrato.usoCupom" class="data-beneficio-modal"> {{ formatarData(dadosExtrato.dataTransacao) }} 
                     <span class="txt-disponivel" v-if="!dadosExtrato.usoCupom && dadosExtrato.motivo == 'Resgate de Benefício'"> - Benefício resgatado</span>
                  </p> 

                  <p class="data-beneficio-modal" v-else-if="dadosExtrato.motivo == 'Resgate de Benefício'"> {{ formatarData(dadosExtrato.dataUsoCupom) }} 
                      <span class="txt-beneficio-utilizado"> - Benefício resgatado</span>
                  </p> 

                  <div class="d-flex box-cupom-resgatado" v-if="dadosExtrato.beneficio.tipo != 'JorgeMateus' && dadosExtrato.beneficio.tipo != 'PreVenda' && dadosExtrato.beneficio.tipo != 'EventoPiata' && dadosExtrato.beneficio.tipo != 'EventoRegional'">
                     <input v-if="dadosExtrato.cupom" class="codigo-cupom" v-model="dadosExtrato.cupom" />
                      <img          
                          src="@/assets/img/copiar.svg"
                          alt="Icone de copiar"
                          class="icone-copiar"
                           @click="copiarTexto($event)"
                       />
                    </div>  

                  <div class="textolegal" v-html="dadosExtrato.beneficio.textoLegal"> </div>

                </div>

                <div v-if="(dadosExtrato.motivo == 'Resgate de Benefício' || dadosExtrato.motivo == 'Indicação')">
                  <div class="selo d-flex" v-if="dadosExtrato.valor">
                      <p class="qtd-ind"><span class="qtd-ind" v-if="dadosExtrato.motivo == 'Indicação'">+</span>{{ dadosExtrato.valor }}</p>
                      <p class="concluido">SALDO DE <br>INDICAÇÃO</p>
                  </div>    
                </div>  

                <div v-else>
                  <p class="data-beneficio-modal"> {{ formatarData(dadosExtrato.dataTransacao) }}  </p>   
                </div>
                
            </div>
        </b-modal>
        </div>
       <TheFooter />
       </div>
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import MenuMobile from "@/components/Base/MenuMobile.vue";
import { format } from 'date-fns';

export default {
  name: "Extrato",
   components: {
    MenuMobile,
    TheFooter
  },
  data() {
    return {
        loading: false,
        dataHoje: new Date(),
        extratos: [],
        dadosExtrato: [],
        mesSelecionado: '',
        anoSelecionado: '',
        todos: false
    };
  },
  computed: {
      extratosFiltrados() {
                    if (!this.mesSelecionado || !this.anoSelecionado || this.todos) {
                        return this.extratos;
                    }
                    const mesSelecionado = parseInt(this.mesSelecionado);
                    const anoSelecionado = parseInt(this.anoSelecionado);
                    return this.extratos.filter(extrato => {
                        const data = new Date(extrato.dataTransacao);
                        return data.getMonth() + 1 === mesSelecionado && data.getFullYear() === anoSelecionado;
                    });
                }
  },
   methods: {
    verTodos() {
      this.todos = true;
      this.mesSelecionado = '';
      this.anoSelecionado = '';
    },
    openModal(extratos) {
        this.dadosExtrato = { ...extratos };
        this.$bvModal.show('modal-extrato');
    },
    voltar() {
        this.$router.push("/home")
    }, 

    copiarTexto(event) {
      const inputElement = event.target.previousElementSibling;
      inputElement.select();
      document.execCommand('copy');
    },  
  
    buscarExtrato() {
          this.loading = true;
          api
            .get('/usuario/extrato')
            .then((resp) => {
              this.extratos = resp.data;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
    },   

    formatarDataTransacao(dataTransacao) {
      return format(new Date(dataTransacao), 'dd/MM/yyyy');
    },

    formatarData(dataTransacao) {
      if (!dataTransacao) {
        return ''; 
      }
      const data = new Date(dataTransacao);
      if (isNaN(data.getTime())) {
        return ''; 
      }
      return format(data, 'dd/MM/yyyy');
    }
  },
  watch: {
    mesSelecionado() {
        this.todos = false;
    },
    anoSelecionado() {
        this.todos = false;
    }
  },
  mounted() {
    this.buscarExtrato();
  }
};
</script>

<style scoped>

  .data-beneficio {
    font-family: "klavika medium" !important;
    font-size: 15px !important;
    color: #FFFFFF !important;
  }

  .data-beneficio-modal {
    padding: 10px 0px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-family: "klavika medium" !important;
    font-size: 15px !important;
    color: #000 !important;
  }

  .nome-beneficio {
      color: #fff !important;
      font-family: "klavika medium" !important;
      font-size: 17px !important;
  }

  .txt-disponivel {
    color: #D0EA8E !important;
    font-family: "klavika medium" !important;
    font-size: 15px !important;
    letter-spacing: 1px;
  }

  .txt-beneficio-utilizado {
    letter-spacing: 1px;
    color: #00ABC8 !important;
    font-family: "klavika medium" !important;
    font-size: 15px !important;
  }

  .icone-copiar {
    cursor: pointer;
    filter: invert(1);
  }

  .codigo-cupom {
    padding: 0px;
    border: none;
    font-family: "klavika medium";
    color: #000000;
    font-size: 25px;
  }

  .email-indicado {
    color: #00abc8;
  }

  .todos {
    margin-bottom: 0px;
    background-color: #00abc8;
    border: none;
    color: white;
    padding: 3px 20px 0px 20px;
    height: 56px;
  }

  .todos:hover {
  background-color: #00b6d5;
}

  .msg-resultado {
    color: white;
    text-align: center;
    margin-top: 40px;
    font-size: 25px;
    font-family: "klavika medium";
  }

  .lista-extrato {
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: auto;
    overflow-x: hidden;
    height: 400px;
  }

  .info-extrato {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
  }

  .box-select label {
    color: white;
  }

  .dataExtrato {
    margin-right: 5px;
    align-items: center;
  }

  .dadosPontos {
    line-height: 20px;
  }

  .dadosPontos h3 {
    text-transform: uppercase;
    color: white;
    font-size: 22px;
    margin-bottom: 0px !important;
    font-family: "klavika medium";
  }

  .dataExtrato p {
    margin-bottom: 0px;
    color: white;
    margin-right: 20px;
    font-size: 35px;
    font-family: "klavika medium";
  }

  .beneficio {
    color: #fff !important;
  }

  .beneficio-indicacao {
    color: #D0EA8E !important;
  }

  .dadosPontos p {
    font-family: "klavika bold";
    color: #D0EA8E;
    font-size: 31px;
    margin-bottom: 0px !important;
  }

  .icone-extrato {
    width: 58px;
    margin-right:15px;
  }

  .outros {
    width: 58px;
    margin: auto;
  }

  .bgextrato {
    background-image: url('@/assets/img/extrato-desk.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .th-extrato {
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    text-align: center;
    color: #D0EA8E;
    font-size: 48px;
    letter-spacing: 0.96px;
    margin-top: 60px;
  }

  .conteudo-extrato {
    width: 435px;
    margin: 0 auto;
  }

  .modal-beneficio {
    color: #dc5555 !important;
  }

  .conteudo-extrato select {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    width: 130px;
  }

  .box-select {
    align-items: end;
    gap: 20px;
    margin-top: 40px;
    justify-content: end;
  }

    .anterior {
        background-color: transparent;
        border: none;
    }

    .seta {
        width: 14px;
        margin-right: 15px;
        cursor: pointer;
    }

    .voltar {
      padding: 0px !important;
      display: flex;
      margin: 15px 0px 30px 0px;
    }

    .voltar span {
        color: #fff;
        font-weight: 200;
    }

    .voltar p {
        margin-bottom: 0px;
        line-height: 17px;
        color: #fff;
        font-size: 14px;
        font-family: "klavika-web", sans-serif;
        font-weight: 700;
    }

    .logo-rodape {
        margin: 0 auto;
        padding-top: 80px;
    }

  .logo-mobile {
    width: 200px;
    top: 35px;
    left: 20px;
  }

  .menu-mobile {
    text-align: right;
    float: right;
    position: relative;
    right: 40px;
  }

  .nav-item a {
    color: #FFFFFF;
    font-size: 22px;
    font-family: "klavika light";
    font-weight: 300;
  }

  .nav-item a:hover {
    color: #D4D1C4;
  }

  .active a:after {
    content: '';
    display: inline-block;
    width: 8px; 
    height: 8px; 
    border-radius: 50%;
    background-color: #FF681E; 
    margin-left: 12px;
    position: relative;
    top: -3px;
  }

  .active a {
    color: #D4D1C4 !important;
  }

  .mob {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0px !important;
      padding-top: 20px;
  }

  .copyright {
    margin-top: 20px;
  }

.selo {
    border: 2px dashed #52575A;
    padding: 5px 20px;
    align-items: center;
    height: 85px;
    gap: 10px;
    margin: auto;
    width: 260px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #fff !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
     margin-top: 0px !important;
}

.concluido {
    margin-top: 0px !important;
    text-align: left !important;
    margin-bottom: 0px !important;
    color: #52575Aff !important;
    font-family: "KNOCKOUT-HTF70" !important;
    font-size: 27px !important;
    line-height: 30px;
}
@media (max-width: 990px) {

  .conteudo-extrato select {
    padding: 0px;
    width: 110px;
  }

  .dataExtrato p {
    margin-right: 5px;
    font-size: 26px;
  }

  .nome-beneficio {
    font-size: 14px !important;
  }

  .todos {
      font-size: 14px;
      padding: 3px 15px 0px 15px;
  }
  .ano {
    width: 85px !important;
  }

  .box-select{
    margin-top: 20px;
    justify-content: center;
  }

  .conteudo-extrato {
    width: 100%;
  }

  .logo-mobile {
    width: 150px;
  }
  
  .copyright {
    margin-bottom: 30px;
  }

  .th-extrato {
    font-size: 48px;
    line-height: 45px;
    margin-top: 20px;
    text-align: left;
  }

  .lista-extrato {
    margin-bottom: 250px;
    height: 250px;
    margin-top: 20px;
  }

  .info-extrato {
    margin-bottom: 20px;
  }

  .dadosPontos h3 {
    font-size: 16px;
  }

  .dadosPontos p {
    font-size: 23px;
  }

    .bgextrato {
      background-image: url('@/assets/img/extratomob2.png');
    }

}
</style>