<template>
        <div>
            <!------------ MINHA COLEÇÃO DESKTOP ------------------->
            <div class="slide-colecao container" v-if="dadosProdutos.length > 0">
                <b-container>
                    <b-row>
                        <b-col cols="12">
                            <carousel :perPageCustom="[[0, 1], [768, 5]]" :navigation-enabled="true">
                                <slide class="p-2" v-for="(produto, index) in dadosProdutos" :key="index">
                                    <div class="box-produto">
                                        <div class="img-produto" v-if="produto">
                                            <img :src="produto.imgAlternativa ? produto.imgAlternativa : 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/IMG_PROD_GENERICO.png'"
                                                class="imgUrl" />
                                            <p class="unidades" v-if="produto.produtos && totalQuantidade(produto) > 1">
                                                {{ totalQuantidade(produto) }} unidades
                                            </p>

                                            <img v-if="produto.moldura"
                                               :src="`https://emailimagesnb-v3.s3.sa-east-1.amazonaws.com/oink/legionarios/cards/${produto.moldura}.png`" alt="Imagem de moldura" class="moldura"/>

                                            <img v-if="produto.produtos.some(p => p.produtoValidado)"
                                                src="@/assets/img/VERIFICADO.svg" alt="ícone de verificado"
                                                class="icone-verificado" />

                                            <img v-if="totalCompradoParaPresente(produto) > 0"
                                                src="@/assets/img/GIFT.svg" alt="ícone de verificado"
                                                class="icone-gift" />
                                                
                                            <img v-if="totalPresente(produto) > 0" src="@/assets/img/GIFT.svg"
                                                alt="ícone de verificado" class="icone-gift" />
                                                
                                            <div class="selo-numero">
                                                <img v-if="produto && produto.produtos && produto.produtos[0].legionario"
                                                    src="@/assets/img/selo-copo.png" alt="Imagem do selo"
                                                    class="selo-copo" />

                                                <h3 v-if="produto && produto.produtos && produto.produtos[0].legionario"
                                                    class="txt-numero">{{ produto.produtos[0].codigoLegionario }}/1913
                                                </h3>
                                            </div>

                                        </div>
                                        <div class="info-produto bg-especial" :class="[produto && produto.moldura ? produto.moldura : '', produto.moldura === 'burger-king-2024' ? 'bg-bk' : '']"  v-if="produto"
                                            :style="produto && produto.produtos && produto.produtos[0].legionario ? 
                                            { backgroundImage: 'url(https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/bg-especial.png)' } : 
                                            (produto.moldura === 'burger-king-2024' ? { backgroundImage: 'url(https://emailimagesnb-v3.s3.sa-east-1.amazonaws.com/oink/legionarios/cards/fogo.png)' } : {})">
                                            <h3>{{ produto.nome }}</h3>
                                            <div class="d-flex categoria-box">
                                                <div class="d-flex def-cat">
                                                    <img v-if="produto.categoria == 'Bar'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/BAR.png" alt="Imagem da categoria" />

                                                    <img v-else-if="produto.categoria == 'Café'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/CAFE.png" alt="Imagem da categoria" />

                                                    <img v-else-if="produto.categoria == 'Hidratação'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/HIDRAT.png" alt="Imagem da categoria" />

                                                    <img v-else-if="produto.categoria == 'Camping'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/CAMP.png" alt="Imagem da categoria" />

                                                    <img v-else-if="produto.categoria == 'Youth'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/YOUTH.png" alt="Imagem da categoria" />

                                                    <img v-else-if="produto.categoria == 'Mate'"
                                                        class="image-cat"
                                                        src="@/assets/img/icones/MATE.png" alt="Imagem da categoria" />

                                                    <p>{{ produto.categoria }}</p>
                                                </div>
                                                <router-link :to="`/editar-produto/${produto.id}`" class="editar">
                                                     VER MAIS
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>

                                </slide>

                            </carousel>
                        </b-col>
                    </b-row>

                   
                </b-container>
            </div>

        </div>
</template>
<script>

import { api } from "@/services.js";
export default {
    name: "Produtos",
    data() {
        return {
            dadosProdutos: [],
            loading: false,
            usuarioLogado: [],
            cpf: "",
            slide: 0,
            sliding: null,
        }
    },

    computed: {
        nenhumProdutoValidado() {
            return this.dadosProdutos.every(produto => 
                produto.produtos && produto.produtos.every(p => !p.produtoValidado)
            );
        },
        exibirBannerPiata() {
            return this.dadosProdutos.some(produto =>
                produto && produto.produtos.some(p => p.produtoValidado)
            );
        }
    },
     methods: {
        totalCompradoParaPresente(produto) {
            if (!produto.produtos || produto.produtos.length === 0) {
                return 0;
            }
            return produto.produtos.reduce((total, item) => {
                return total + (item.compradoParaPresente || 0);
            }, 0);
        },

        totalPresente(produto) {
            if (!produto.produtos || produto.produtos.length === 0) {
                return 0;
            }
            return produto.produtos.reduce((total, item) => {
                return total + (item.presente || 0);
            }, 0);
        },
        totalQuantidadeColecao(produtos) {
            if (!produtos || produtos.length === 0) {
                return 0;
            }
            return produtos.reduce((total, item) => {
                return total + this.totalQuantidade(item)
            }, 0);
        },
        totalQuantidade(produto) {
            if (!produto.produtos || produto.produtos.length === 0) {
                return 0;
            }
            return produto.produtos.reduce((total, item) => {
                return total + (item.quantidade || 0);
            }, 0);
        },
        async buscarUsuarios() {
            try {
                this.loading = true;
                const resp = await api.get('/usuario/logged');
                this.usuarioLogado = resp.data;
                this.cpf = this.usuarioLogado.cpf;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        async produtosCadastrados() {
            try {
                this.loading = true;
                await this.buscarUsuarios();
                const resp = await api.get(`/produto/cliente?cpf=${this.cpf}`);
                
                this.dadosProdutos = resp.data.filter(produto => {
                    return produto.produtos.reduce((total, item) => {
                       return total + ((item.compradoParaPresente && item.produtoValidado) ? 0 : 1);
                    }, 0);
                });

            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        legionarioResgatou() {
            for (let produto of this.dadosProdutos) {
                if (produto.produtos && produto.produtos[0].legionario == true) return true;
            }
            return false;
        }
    },
    mounted() {
        this.produtosCadastrados();
        this.dadosProdutos.length = '1';
    }
};
</script>

<style scoped>
.titulo-colaborador {
    margin-bottom: 70px !important;
}

.bg-especial {
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.bg-bk {
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-indicacao {
    background-image: url('@/assets/img/bannerIndicacoes/Banner_MOB.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 800px;
}

.bg-indicacao img {
    width: 235px;
    margin-top: 230px;
    margin-left: 10px;
}

.passosBeneficios {
    background-color: black;
}

.carouselBeneficios {
    margin: 20px 0px 50px 0px;
}

.card-passo {
    background-color: white;
    padding: 40px 20px !important;
    border-radius: 15px;
    width: 100%;
    min-height: 560px;
}

.card-passo img {
    width: 268px !important;
}

.msgSemProduto {
    font-family: "klavika medium";
    font-weight: 900;
    color: #FF681E;
    font-size: 44px;
    line-height: 45px;
    margin-bottom: 0px !important;
}

.semCadastro {
    margin: 0 auto;
    display: block;
    width: 900px;
    margin-bottom: 100px !important;
}

.box-cadastro-prod {
    gap: 20px;
}

.frase-prod {
    width: 550px;
}

.iconeproduto {
    width: 130px;
}

.semCadastro p {
    letter-spacing: 1.8px;
    color: #232527;
    font-family: "klavika light";
    font-size: 19px;
}

.section-colecao {
    background-color: #fff;
}

.editar {
    background-color: #00ABC8;
    color: #fff;
    text-decoration: none;
    border-radius: 8px;
    padding: 3px 10px;
}

.editar:hover {
    background-color: #00b6d5;
}

.categoria-box {
    align-items: center;
    justify-content: space-between;
    position: absolute !important;
    bottom: 20px !important;
    width: 85%;
}

.info-produto {
    position: relative;
    height: 150px;
    background-color: #708573;
    border-radius: 12px;
    color: #fff;
    padding: 25px 20px 0px 20px;
}

.info-produto p {
    margin-bottom: -3px;
    color: #fff;
    font-family: "klavika light";
    font-size: 14px;
    text-transform: uppercase;
}

.info-produto h3 {
    font-family: "klavika light";
    font-size: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.box-produto {
    width: 100%;
    position: relative !important;
}

.img-produto {
    width: 100%;
    position: relative;
}

.imgUrl {
    margin-bottom: -16px;
    width: 245px;
    border-radius: 20px 20px 0px 0px;
    object-fit: contain;
    height: 245px;
}

.titulo img {
    margin-left: 30px;
    margin-top: 5px;
}

.colecao {
    justify-content: space-between;
    display: flex;
    padding-top: 80px;
    padding-bottom: 40px;
}

.colecao h2 {
    color: #52575A;
    font-size: 72px;
    font-family: "klavika-web", sans-serif;
    letter-spacing: 1.06px;
    font-weight: 900;
    text-transform: uppercase;
}

.txt-prod {
    line-height: 20px;
    width: 700px;
    color: #232527;
    font-size: 16px;
    font-family: "klavika light";
}

.btn-produto {
    text-decoration: none;
    background-color: #FF681E;
    color: white;
    padding: 10px 40px;
    position: relative;
    z-index: 9;
}

.btn-produto:hover {
    background-color: #f05b11;
}

.btn-todos {
    text-decoration: none;
    margin-bottom: 50px !important;
    background-color: #000;
    color: white;
    padding: 10px 40px;
    position: relative;
    z-index: 9;
    width: 250px;
    text-align: center;
    justify-content: center;
    float: right;
    margin-top: 30px;
}

.btn-todos:hover {
    background-color: #00ABC8;
}

.btn-produto img {
    height: 16px;
    width: 17px;
    margin-top: 3px;
    margin-left: 10px;
}

.produto {
    margin-top: 10px !important;
}

.txt-produto {
    top: 45px !important;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    left: 14px;
    font-size: 10px !important;
    color: #f05b11 !important;
}

.numero {
    font-family: "klavika medium" !important;
    font-size: 30px !important;
    color: #f05b11 !important;
}

.slide-colecao {
    display: flex;
    gap: 15px;
    justify-content: start;
    /*overflow: auto;*/
    padding: 0px 0px 10px 0px;
}

.selo-numero {
    bottom: 0px;
    position: absolute;
    bottom: 20px;
    left: 15px;
}

.unidades {
    left: 12px;
    top: 12px;
    position: absolute;
    width: 70px;
    text-align: center;
    font-size: 11px;
    border: 1px solid;
}

.icone-verificado {
    right: 10px;
    top: 10px;
    width: 25px !important;
    position: absolute;
}

.icone-gift {
    right: 10px;
    top: 10px;
    width: 25px;
    position: absolute;
}

.img-categoria {
    right: 15px;
    bottom: 15px;
    position: absolute;
    width: 31px;
}

.sem-produto {
    padding: 30px;
    width: 900px;
    border-radius: 10px;
    background-image: url('@/assets/img/semcadastro.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.sem-produto h3 {
    color: white;
    font-size: 55px;
    line-height: 55px;
    width: 540px;
    font-family: "klavika bold";
}

.selo-cupom {
    border: 2px dashed white;
    color: white;
    font-size: 55px;
    font-family: "klavika bold";
    width: 225px;
    text-align: center;
}

.btn-gerar {
    margin-top: 20px;
}

.btn-gerar-cupom {
    text-decoration: none;
    background-color: #FF681E;
    color: white;
    padding: 5px 20px;
    border-radius: 8px;
}

.selo-copo {
    width: 100px;
}

.txt-numero {
    font-family: "klavika bold";
    color: #232527;
    font-size: 14px;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 10px;
}

.image-cat {
    width: 23px;
}

.def-cat {
    align-items: center;
    gap: 7px;
}

.luan-santana {
    background-color: #571FC8;
}

.olivia-rodrigo-2024 {
    background-color: #694480;
}

.burger-king-2024 {
    background-color: #7E0E00;
}

.barbie-dynamite {
    background-color: #6F1D46;
}

.barbie-peaches {
    background-color: #FAC1B8;
}

.barbie-1959 {
    background-color: #6DC6EC;
}

.barbie-twist {
    background-color: #E7004C;
}

.barbie-rockers {
    background-color: #0089CA;
}

.barbie-ken {
    background-color: #1E1E1E;
}

.barbie-superstar {
    background-color: #9F4299;
}

.barbie-pink {
    background-color: #EF61A5;
}

.messi-goat {
    background-color: black;
}

.moldura {
    top: 0;
    position: absolute;
}

@media (min-width: 991px) and (max-width: 1399px) {
    .colecao h2 {
        font-size: 70px;
    }

    .informacoes h1 {
        font-size: 95px;
    }

    .informacoes p {
        font-size: 55px;
        margin-top: -33px;
    }

    .info-produto h3 {
        font-size: 12px;
    }

    .editar {
        padding: 3px 8px;
        font-size: 14px;
    }

    .categoria-box {
        position: absolute;
        bottom: 15px !important;
    }

    .imgUrl {
        margin-bottom: -6px;
        width: 205px;
        height: 205px;
    }

    .editar {
        font-size: 12px;
        padding: 3px 6px; 
    }

    .info-produto p {
        font-size: 13px;
    }

    .image-cat {
        width: 18px;
    }

    .info-produto {
        padding: 25px 10px 0px 10px;
        height: 120px;
    }
}

@media (min-width: 991px) {

    .vermobile {
        display: none;
    }

    .mob {
        display: none;
    }

    .box-qtd {
        position: relative;
    }

    .numero {
        top: 10px !important;
        position: absolute;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        left: 13px;
    }
}

@media (max-width: 990px) {

    .dedf-cat {
        gap: 10px;
    }

    .btn-todos {
        width: 260px;
        float: initial;
        margin-bottom: 30px ! IMPORTANT;
        margin: auto;
    }

    .moldura {
        width: 100%;
    }
    .estiloMobile {
        justify-content: center !important;
    }

    .semCadastro {
        margin-bottom: 30px !important;
        padding: 0px 20px !important;
    }

    .slide-colecao {
        overflow: auto;
    }

    .verdesktop {
        display: none !important;
    }

    .sem-produto,
    .sem-produto h3 {
        width: 100%;
    }

    .sem-produto {
        background-image: url('@/assets/img/semcadastromob.png');
        background-size: cover;
    }

    .sem-produto h3 {
        font-size: 36px;
        line-height: 40px;
    }

    .selo-cupom {
        font-size: 30px;
        width: 130px;
    }

    .semCadastro {
        padding: 0px;
    }

    .semCadastro,
    .frase-prod {
        width: 100%;
    }

    .msgSemProduto {
        margin-bottom: 10px !important;
        font-size: 17px;
        line-height: 21px;
    }

    .semCadastro p {
        font-size: 15px;
        line-height: 16px;
    }

    .box-qtd {
        position: absolute;
        right: 15px;
    }

    .desk {
        display: none;
    }

    .txt-prod {
        width: 100%;
    }

    .colecao,
    .slide-colecao {
        padding: 20px !important;
    }

    .colecao h2 {
        font-size: 50px;
        line-height: 50px;
    }

    .titulo img {
        margin-left: 0px;
    }

    .numero {
        top: 10px;
        position: absolute;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .txt-produto {
        font-size: 10px !important;
        right: 0px;
        left: 0px;
        top: 80px;
        position: absolute;
    }

    .produto {
        padding-top: 20px;
        width: 260px;
        margin: 0px auto;
    }

    .btn-produto {
        justify-content: center;
        margin-bottom: 30px;
    }

    .img-produto,
    .box-produto,
    .imgUrl {
        width: 300px;
        margin: auto;
    }

    .box-cadastro-prod {
        margin-top: 20px;
    }

    .imgUrl {
        height: 305px;
        margin-bottom: -14px;
    }

}
</style>