<template>
  <div class="background">

    <div class="loading_global" v-if="loading">
        <span class="loader"></span>
    </div>
    <section v-else>
      <Indique />
    </section>
    <section class="secao-visao">
       <b-container>
         <b-row>
            <b-col cols="12" md="5">
                <h2 class="visao">VISÃO GERAL<br> DE INDICAÇÕES</h2>
                <p class="txt-visao">As pessoas que você indicou e aquelas que se inscreveram mas estão pendentes de confirmação</p>
            </b-col>
            <b-col cols="12" md="7">
               <div class="tab-emails">
                  <b-tabs content-class="mt-3">
                    <b-tab active>
                      <template v-slot:title>
                        <span class="title-static">CONFIRMADO: </span>
                        <span class="title-dynamic">{{ indicacoes.confirmados }}</span>
                      </template>
                         <ul v-if="indicacoes.confirmados">
                            <li v-for="email in indicacoes.emailsConfirmados" :key="email">
                              {{ email }}
                            </li>
                        </ul>
                        <div v-else>
                          <p class="msg-pendente">Nenhum email confirmado</p>
                        </div>
                    </b-tab>
                    <b-tab>
                       <template v-slot:title>
                          <span class="title-static">PENDENTES: </span>
                          <span class="title-dynamic">{{ indicacoes.pendentes }}</span>
                        </template>
                        <ul v-if="indicacoes.pendentes">
                          <li v-for="email in indicacoes.emailsPendentes" :key="email">
                            {{ email }}
                          </li>
                        </ul>
                        <div v-else>
                          <p class="msg-pendente">Nenhum email pendente</p>
                        </div>
                    </b-tab>
                  </b-tabs>
                </div>
            </b-col>
          </b-row>

          <b-row class="row-porque">
            <b-col cols="12" md="5">
                <h2 class="visao-porque">POR QUE <br> COMPARTILHAR O LINK?</h2>
                <p class="txt-visao-porque">Compartilhando seu link, você acumula indicações e pode trocar por benefícios nas suas próximas compras. Não há limite para quantas pessoas você pode indicar, então quanto mais você compartilha, mais você ganha!</p>
            </b-col>

            <!-- <b-col cols="12" md="7">
                <div v-if="etapa === 1" data-anima="right">
                    <div class="d-flex etapa">
                        <div class="etapa-1 box-etapa">
                            <div class="d-flex titulo-indique desktop">
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>1</h2>
                              <h3>COPIE O CÓDIGO</h3>
                            </div>

                            <div class="mobile">
                                <div>
                                  <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                  <h2>1</h2>
                                </div>
                                <h3>COPIE O CÓDIGO</h3>
                            </div>

                            <p class="txt-info">Aqui nessa mesma página, você encontrará um código exclusivo que identifica todas as suas indicações.</p>
                            
                            <div class="d-flex botao">
                              <button @click="numeroEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">1/3</p>
                              <button @click="proximaEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel1.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>
                    
                </div>

                <div v-if="etapa === 2" data-anima="right">

                  <div class="d-flex etapa">
                        <div class="box-etapa">
                            <div class="d-flex titulo-indique desktop">
                              <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                              <h2>2</h2>
                              <h3>COMPARTILHE SEU CÓDIGO</h3>
                            </div>

                            <div class="mobile">
                              <div>
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>2</h2>
                              </div>
                              <h3>COMPARTILHE SEU CÓDIGO</h3>
                            </div>

                            <p class="txt-info-etapa">Compartilhe o código para seus amigos se cadastrem no nosso Portal. Lembrando que quanto mais indicar, mais pontos você pode acumular.</p>

                            <div class="d-flex botao">
                              <button @click="anteriorEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">2/3</p>
                              <button @click="proximaEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel2.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>
                </div>
                    
                <div v-if="etapa === 3" data-anima="right">
                    <div class="d-flex etapa">
                        <div class="box-etapa">
                            <div class="d-flex titulo-indique desktop">
                              <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                              <h2>3</h2>
                              <h3>LIBERE BENEFÍCIOS</h3>
                            </div>

                            <div class="mobile">
                              <div>
                                <img src="@/assets/img/icone-indique.png" alt="Ícone da stanley" class="iconepasso"/>
                                <h2>3</h2>
                              </div>
                              <h3>LIBERE BENEFÍCIOS</h3>
                            </div>

                            <p class="txt-info-etapa">Quando seu indicado se cadastra com seu link, automaticamente completa missões e pode trocar por diversos benefícios.</p>

                           <div class="d-flex botao">
                              <button @click="anteriorEtapa" class="btn-voltar">
                                 <img src="@/assets/img/seta-esquerda.png" alt="Ícone da stanley"/>
                              </button>
                              <p class="numetapa">3/3</p>
                              <button @click="numeroEtapa" class="btn-proximo">
                                <img src="@/assets/img/seta-direita.png" alt="Ícone da stanley"/>
                              </button>
                            </div>
                        </div>
                        <div>
                            <img src="@/assets/img/cel3.png" alt="Ícone da stanley" class="img-passo"/>
                        </div>
                    </div>
                </div>
            </b-col>-->

            <b-col cols="12" md="7">
              <carousel 
                :perPageCustom="[[0, 1], [768, 1.5]]"
                :navigation-enabled="true" 
                :scrollPerPage="false" 
                ref="carouselDesktop"
              >
                <slide class="p-2">
                  <div class="card-passo">
                    <img src="@/assets/img/passo1.png" class="item-passo verdesktop" alt="Imagem mostrando o passo a passo" />
                    <img src="@/assets/img/passomob1.png" class="item-passo vermobile" alt="Imagem mostrando o passo a passo" />
                  </div>
                </slide>

                <slide class="p-2">
                  <div class="card-passo">                                  
                    <img src="@/assets/img/passo2.png" class="item-passo verdesktop" alt="Imagem mostrando o passo a passo" />
                    <img src="@/assets/img/passomob2.png" class="item-passo vermobile" alt="Imagem mostrando o passo a passo" />
                  </div>
                </slide>

                <slide class="p-2">
                  <div class="card-passo">
                    <img src="@/assets/img/passo3.png" class="item-passo verdesktop" alt="Imagem mostrando o passo a passo" />
                    <img src="@/assets/img/passomob3.png" class="item-passo vermobile" alt="Imagem mostrando o passo a passo" />
                  </div>
                </slide>
              </carousel>
            </b-col>

          </b-row>

      </b-container>
    </section>
    <section>
        <TheFooter />
    </section>    

  </div>
</template>

<script>
import { api } from "@/services.js";
import TheFooter from "@/components/Base/TheFooter.vue";
import Indique from "@/components/Indique.vue";
export default {
  name: "Indicacoes",
  components: {
    TheFooter,
    Indique
  },

  data() {
    return {
      indicacoes: [],
      codigos: [],
      loading: false,
      etapa: 1,
      totalEtapas: 3,
    };
 },
 methods: {

  baixarImagem() {
      const url = 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/stories_indique.png';
      window.open(url, '_blank');
    },

    irInstagram() {
        window.open("https://www.instagram.com", "_blank")
    },

    triggerResize() {
      window.dispatchEvent(new Event('resize'));
    },

    openModal() {
        this.$bvModal.show('modal-instagram');
    },

  numeroEtapa() {
    if (this.etapa == 1) {
      this.etapa = 3;
    } else if (this.etapa == 3) {
      this.etapa = 1;
    }
  },

  voltar() {
        this.$router.push("/home")
  }, 

   proximaEtapa() {
      if (this.etapa < this.totalEtapas) {
        this.etapa++;
      }
    },
    anteriorEtapa() {
      if (this.etapa > 1) {
        this.etapa--;
      }
    },

    copiarTexto() {
      const inputElement = document.querySelector('.copiar input');
      inputElement.select();
      document.execCommand('copy');
      inputElement.focus();
    },

     buscarIndicacoes() {
        this.loading = true;
        api
          .get('/indicacao')
          .then((resp) => {
            this.indicacoes = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    buscarCodigo() {
        this.loading = true;
        api
          .get('/indicacao/codigo')
          .then((resp) => {
            this.codigos = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

  },
   mounted() {
    this.buscarIndicacoes();
    this.buscarCodigo();
  },
};
</script>

<style scoped>

.secao-visao {
  background-color: #fff;
  padding-top: 50px;
}

.icone-instagram {
    cursor: pointer;
}

.btn-entrar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 10px 50px;
    margin: 0px auto; 
    display: block;
    text-align: center;
    width: 300px;
    height: 50px;
    margin-top: 20px !important;
  }

  .btn-entrar:hover {
    background-color: #00b6d5;
  }

.card-passo {
    width: 100%;
}

.box-voltar {
  padding: 0px !important;
}

.btn-voltar {
    background-color: transparent;
    border: none;
    margin-top: 15px;
    width: 40px;
}

.btn-proximo {
    background-color: transparent;
    border: none;
    margin-top: 15px;
    width: 40px;
}

.btn-proximo img {
  float: right;
}
/*
.background {
    background-image: url('@/assets/img/desk-home.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
}
*/
.titulo-indique {
  align-items: center;
  gap: 10px;
}

.iconepasso {
  width: 70px;
}

.img-passo {
  width: 300px;
}

.row-porque {
  margin-top: 25px;
  margin-bottom: 30px;
}

.etapa {
  justify-content: space-between;
  gap: 40px;
}

.etapa-1 .txt-info {
  width: 300px;
  margin-top: 30px;
  color: #000;
  font-size: 18px;
  font-family: "klavika light";
  line-height: 22px;
  margin-top: 50px;
}

.etapa .txt-info-etapa {
  width: 300px;
  margin-top: 30px;
  color: #000;
  font-size: 18px;
  font-family: "klavika light";
  line-height: 22px;
}

.etapa h2 {
  font-family: "KNOCKOUT-HTF70";
  color: #000;
  font-size: 105px;
}

.etapa h3 {
  color: #000;
  font-size: 35px;
  font-family: "klavika medium";
  line-height: 35px;
}
.etapa .box-etapa {
  width: 300px !important;
}

.numetapa {
  text-align: center;
  width: 50px !important;
  color: #000;
  font-size: 29px !important;
  font-family: "klavika bold" !important;
  margin-top: 30px;
}

.title-static {
  color: #000 !important;
  font-family: "klavika bold" !important;
  font-size: 20px;
}

.title-dynamic {
    background-color: #D9D9D6;
    padding: 5px 10px;
    border-radius: 50%;
    font-size: 12px;
    position: relative;
    top: -5px;
}

.tab-emails ul {
  height: 100px;
  overflow: auto;
  padding-left: 0px;
}

.tab-emails li {
  color: #000;
  font-size: 15px;
  font-family: "klavika light";
  border-bottom: 1px solid #D9D8D6;
  padding-bottom: 8px;
  padding-top: 10px;
}


.item-passo {
  width: 500px;
  margin: auto;
  max-width: 100%;
  height: 285px;
  object-fit: fill;
}

.indique {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
}

.msg-pendente {
  letter-spacing: 1.8px;
  font-family: "klavika light";
  color: #000;
  font-size: 19px;
  line-height: 22px;
  margin-top: 30px;
}

.selo {
    border: 2px dashed #000;
    padding: 5px 20px;
    align-items: center;
    width: 300px;
    height: 85px;
    gap: 8px;
}

.selo img {
    width: 45px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #FF681E !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
}

.concluido {
    margin-bottom: 0px !important;
    color: #000 !important;
    font-family: "KNOCKOUT-HTF70" !important;
    font-size: 27px !important;
    line-height: 30px;
    text-align: center;
}

.icones {
    gap: 15px;
}

.h-titulo {
    text-align: center;
    letter-spacing: 1.52px;
    font-family: "klavika medium";
    color: #000;
    line-height: 40px;
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: bold;
}

.h-titulo strong {
    color: #000 !important;
}

.indique .txt-compartilhar {
  margin: 0 auto;
   text-align: center;
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #000;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 30px;
    width: 700px;
}

.txt-visao {
    letter-spacing: 1.8px;
    font-family: "InstrumentSansRegular";
    color: #000;
    font-size: 19px;
    line-height: 25px;
    margin-bottom: 30px;
    width: 350px;
}

.txt-visao-porque {
    letter-spacing: 1.8px;
    font-family: "InstrumentSansRegular";
    color: #000;
    font-size: 17px;
    line-height: 23px;
    margin-bottom: 30px;
    width: 445px;
}

.copiar {
    margin-top: 300px !important;
    width: 700px;
    margin: 0 auto;
    margin-bottom: 30px !important;
}

.copiar input {
    border-radius: 0px;
    background: #00000087;
    border: 1px solid white;
    font-size: 25px;
    color: white;
    padding: 10px 20px;
}

.copiar .btn {
    border-radius: 0px;
    height: 56px;
    width: 150px;
    background-color: #FF681E;
    border: none;
}

.copiar .btn:hover {
    transform: none;
    background-color: #f05b11;
}

.box-redes {
    width: 700px;
    margin: 0 auto;
}

.box-redes p {
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #000;
    font-size: 19px;
    margin-bottom: 0px;
}

.redes-sociais {
    gap: 15px;
    align-items: end;
}


.logo-rodape {
  margin: 0 auto;
  padding-top: 80px;
}

.copyright {
  margin-top: 20px;
}

.visao {
  color: #000;
  letter-spacing: 0.8px;
  font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
  font-size: 40px;
  line-height: 45px;
}

.visao-porque {
  margin-top: 40px;
  color: #000;
  letter-spacing: 0.8px;
  font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
  font-size: 29px;
  line-height: 34px;
}

.carousel-item {
    width: 300px;
    background: rgb(171, 171, 171) !important;
}


@media (min-width: 991px) {

    .vermobile {
        display: none;
    }
}

@media (max-width: 990px) {

  .visao-porque {
    margin-top: 0px;
  }

  .tab-emails {
    margin-bottom: 40px !important;
    margin-top: 40px !important;
  }
  .card-passo {
    padding: 0px 0px !important;
  }

  .verdesktop {
    display: none;
  }

  .etapa {
    display: block !important;
    gap: 0px;
  }

  .etapa .txt-info-etapa, .etapa .txt-info {
    text-align: center;
    width: 100% !important;
    margin-top: 0px!important;
  }

  .etapa .box-etapa {
    justify-content: center;
    width: 100%!important;
  }

  .botao {
    justify-content: center;
  }

  .item-passo {
    width: 100%;
    height: 565px;
  }

  .numetapa {
    margin-top: 13px !important;
    margin-bottom: 0px !important;
  }

  .img-passo {
    padding-top: 20px !important;
    margin: 0 auto;
  }

  .copyright {
    margin-bottom: 30px;
  }

  .logo-rodape {
    margin: 0 auto;
    padding-top: 80px;
  }

  .indique, .box-redes, .copiar, 
  .indique .txt-compartilhar, .tab-emails {
    width: 100% !important;
  }

  .indique .txt-compartilhar {
      font-size: 15px;
      line-height: 18px;
  }

  .box-indique {
    padding: 0px;
  }

  .h-titulo {
    line-height: 30px;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .copiar .btn {
    height: 50px;
    width: 100px;
  }

  .copiar input {
    font-size: 15px;
  }

  .redes-sociais {
    display: block !important;
  }

  .icones {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }

  .box-redes p {
    text-align: center;
  }

  .selo {
      width: 100%;
      gap: 25px;
  }

  .title-static {
    font-size: 13px;
  }
  .copiar {
    margin-top: 250px !important;
  }

  .desktop {
    display: none !important;
  }

  .mobile div {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .etapa h3 {
    text-align: center;
  }

  .visao {
    font-size: 28px;
    line-height: 35px;
  }

  .txt-visao {
    margin-bottom: 0px;
  }

  .row-porque {
    margin-top: 0px;
  }

  .txt-visao-porque, .txt-visao {
    width: 100%;
  }
}

@media (min-width: 991px) {
  .mobile {
    display: none !important;
  }
}

.mob {
  padding-top: 20px;
  margin-top: 0px !important;
}
</style>