<template>
    <div class="secao-faq">
        <b-container>
            <b-row class="row-faq">
                <b-col class="conteudo-tab" cols="12" lg="6"> 
                <h3 class="txtduvidas">FAQ <br>LEGIONÁRIOS</h3>
                </b-col>
                <b-col cols="12" lg="6">
                    <div class="conteudo-faq">
                        <!---- PERGUNTA 1 ------>
                            <div class="mb-4">
                            <button v-b-toggle.pergunta1 class="pergunta">
                                                                    
                                <img
                                    src="@/assets/img/seta-direita.png"
                                    alt="Icone de seta"   
                                />
                                    Como posso resgatar os benefícios disponíveis?
                                </button>
                            </div>

                            <b-collapse id="pergunta1">
                                <b-card class="resposta"> 
                                Para resgatar um benefício, você precisar acessar a seção de benefícios e clicar no item desejado. O processo de resgate será detalhado na página de cada item. Alguns benefícios estão liberados, outros necessitam de saldo de indicações para o resgate.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 2 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta2 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                       Como funciona o saldo de indicações?
                                </button>
                            </div>

                            <b-collapse id="pergunta2">
                                <b-card class="resposta"> 
                                    Como membro do Legionários, você tem um link exclusivo para convidar amigos e familiares e pode trocar por descontos! Cada pessoa que se cadastrar através do seu link conta como 1 (uma) indicação. Você pode conferir o seu link aqui.
                                    Importante: a indicação só será contabilizada quando a pessoa que você enviou o seu link personalizado completar todo o processo de cadastro, incluindo dados solicitados e ativação da conta.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 3 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta3 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                       Minhas indicações expiram?
                                </button>
                            </div>

                            <b-collapse id="pergunta3">
                                <b-card class="resposta"> 
                                    Não, suas indicações não possuem prazo de validade.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 4 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta4 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Posso reutilizar minhas indicações para novos resgates?
                                </button>
                            </div>

                            <b-collapse id="pergunta4">
                                <b-card class="resposta"> 
                                    Não, cada indicação no Legionários pode ser utilizada para resgatar benefícios apenas uma vez. Ou seja, se você tinha 3 indicações e as utilizou para resgatar um benefício, essas indicações já foram contabilizadas e não poderão ser usadas novamente. Para novos resgates, será necessário acumular mais indicações.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 5 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta5 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Posso devolver um benefício do portal?
                                </button>
                            </div>

                            <b-collapse id="pergunta5">
                                <b-card class="resposta"> 
                                    Cupons de desconto podem ser devolvidos em até 7 dias após o resgate, desde que não tenham sido utilizados. Para solicitar a devolução, entre em contato com nossa equipe de suporte clicando aqui. Outros tipos de benefícios não podem ser devolvidos após o resgate, como ingressos para eventos.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 6 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta6 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Posso transferir um benefício resgatado para outra pessoa?
                                </button>
                            </div>

                            <b-collapse id="pergunta6">
                                <b-card class="resposta"> 
                                    Não, os benefícios do portal são intransferíveis.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 7 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta7 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Os benefícios têm validade?
                                </button>
                            </div>

                            <b-collapse id="pergunta7">
                                <b-card class="resposta"> 
                                    Sim, cada benefício tem uma data de validade específica, que estará informada no momento do resgate. Fique atento para não perder a oportunidade!
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 8 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta8 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      O que faço se um benefício não funcionar ou tiver problemas?
                                </button>
                            </div>

                            <b-collapse id="pergunta8">
                                <b-card class="resposta"> 
                                    Entre em contato com nosso suporte clicando aqui e informe com o maior número de detalhes possível o seu caso. Nossa equipe está pronta para ajudar.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 9 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta9 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Como acompanho novidades e novos benefícios no portal Legionários?
                                </button>
                            </div>

                            <b-collapse id="pergunta9">
                                <b-card class="resposta"> 
                                    As novidades são enviadas por e-mail para os membros cadastrados. Certifique-se de que você deu aceite para receber comunicações e mantenha seu e-mail e telefone sempre atualizados.
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 10 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta10 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Posso cancelar minha conta no portal?
                                </button>
                            </div>

                            <b-collapse id="pergunta10">
                                <b-card class="resposta"> 
                                    Sim, para solicitar o cancelamento da sua conta no Legionários, você precisa abrir uma solicitação junto ao nosso time de atendimento clicando <a href="https://www.stanley1913.com.br/pages/contato" target="_blank">aqui.</a>
                                </b-card>
                            </b-collapse>

                            <!---- PERGUNTA 11 ------>
                            <div class="mb-4">
                                <button v-b-toggle.pergunta11 class="pergunta">                             
                                    <img
                                        src="@/assets/img/seta-direita.png"
                                        alt="Icone de seta"   
                                        />
                                      Como posso enviar sugestões ou críticas sobre o programa?
                                </button>
                            </div>

                            <b-collapse id="pergunta11">
                                <b-card class="resposta"> 
                                    Para enviar suas sugestões ou críticas sobre o programa, é só mandar um e-mail para o nosso time de atendimento, que direcionará para o setor responsável. Sua opinião faz toda a diferença para continuarmos melhorando e trazendo ainda mais benefícios para você!
                                </b-card>
                            </b-collapse>
                        </div>                                   
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  

<script>
export default {

}
</script>

<style>

.secao-faq {
    background-color: #4097DB;
    padding: 40px 0px;
}

.txtduvidas {
    font-size: 42px;
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    letter-spacing: 2.31px;
    color: #fff;
    width: 500px;
}

.txtesclarece {
    color: #FFFFFF;
    letter-spacing: 1.71px;
    font-family: "klavika bold";
    font-size: 18px;
}

.pergunta {
    text-align: left;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 20px;
    font-family: "klavika bold";
    letter-spacing: 1.9px;
    margin-right: 20px;
}

.resposta {
    letter-spacing: 1px;
    color: #fff;
    font-size: 20px;
    font-family: "klavika light" !important;
    background-color: transparent;
    border: none;
    margin-bottom: 30px;
}

.resposta a {
    color: white !important;
}

.card {
    border: none !important;
}

.resposta .card-body {
    color: white;
    border: none !important;
    background: #4097db;
    letter-spacing: 1.5px;
    font-family: "klavika light" !important;
    padding: 0px 30px !important;
}

.conteudo-faq {
    height: 180px;
    overflow-y: scroll;
}

@media (min-width: 991px) {

    .mob {
        display: none;
    }

    .row-faq {
        align-items: center;
    }
}

@media (max-width: 990px) {

    .conteudo-faq {
        margin-top: 0px; 
    }

    .txtduvidas {
        font-size: 25px;
        line-height: 23px;
        margin-top: 5px;
        width: 100%;
        margin-bottom: 30px;
    }

    .txtesclarece {
         margin-bottom: 20px;
    }

    .pergunta, .resposta {
        font-size: 16px;
        line-height: 21px;
    }
}

</style>