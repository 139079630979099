<template>
    <div>
        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>

        <section :class="interna ? 'indique-interna' : 'indique'" v-else>  
            <div class="container">
                 <MenuMobile v-if="!home" class="menu-indicacoes"/>
                <h2 class="h-titulo t-desk">INDIQUE O <br>LEGIONÁRIOS</h2>
                <h2 class="h-titulo t-mob">INDIQUE O LEGIONÁRIOS</h2>
                 <img src="@/assets/img/ganhe.png" alt="GANHE" class="ganhe"/>
                <p class="txt-compartilhar"><strong>Copie o link abaixo e compartilhe. </strong><br>Acumule indicações e troque por descontos de até <strong>30% OFF.</strong></p>

                <div class="copiar">
                    <b-input-group class="mt-3">
                        <b-form-input v-model="codigos.url"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="info" @click="copiarTexto">COPIAR</b-button>
                        </b-input-group-append>
                    </b-input-group>

                </div>
                <div class="redesSociais">
                    <div class="box-redes">
                        <p>Compartilhar:</p>

                        <div class="redes-sociais d-flex justify-content-start">
                            <div class="d-flex icones">
                                <!--<a :href="'mailto:?subject=Portal Legionários | Link de indicação&body=Olá, tudo bem? Você é meu convidado especial para participar do Portal Legionários, o hub de fãs da Stanley onde você consegue cadastrar seus produtos, completar missões e resgatar benefícios e cupons de descontos exclusivos.%0A%0AÉ simples de participar: Basta fazer seu cadastro no link a seguir ' + codigos.url + ' e validar sua conta no e-mail.%0A%0AJunte-se a nós para aproveitar todas as vantagens e oportunidades exclusivas.'" target="_blank">
                                    <img src="@/assets/img/email.svg" alt="Ícone email"/>
                                </a>-->

                                <a :href="'mailto:?subject=Portal Legionários | Link de indicação&body=' + encodeURIComponent('Olá! Você é meu convidado especial para participar do Portal Legionários, o hub de fãs da Stanley onde você consegue cadastrar seus produtos, completar missões e resgatar benefícios e cupons de descontos exclusivos. É simples de participar: Basta fazer seu cadastro no link a seguir ' + codigos.url + ' e validar sua conta no e-mail. Junte-se a nós.')"
                                    target="_blank">
                                    <img src="@/assets/img/email.svg" alt="Ícone email"/>
                                </a>
                                                                
                                <img src="@/assets/img/instagram.svg" alt="Ícone instagram" @click="openModal()" class="icone-instagram" />
                            
                                <a :href="'https://api.whatsapp.com/send?text=' +
        'Olá!' +
        '\nTenho um cupom de 20% de desconto* exclusivo para você usar no site da Stanley (www.stanley1913.com.br).' +
        '\nUse o código ' + codigos.url + ' no seu carrinho de compras para usufruir desse benefício.' +
        '\nAproveite e depois me conta o que você comprou :)' +
        '\n*Esse código tem validade até o último dia do mês e não é válido para os Copos Quencher em suas variações de cores e tamanho.'"
        target="_blank">                                
        
        <img src="@/assets/img/wpp.svg" alt="Ícone whatsapp"/> 
                                </a>
                            
                            </div> 

                            <div class="selo d-flex">
                                <p class="qtd-ind">{{ indicacoes.confirmados }}</p>
                                <p class="concluido">INDICAÇÕES CONCLUÍDAS</p>
                                <img src="@/assets/img/share.svg" alt="Ícone de compartilhar"/>
                            </div>  
                        </div>
        
                    </div>

                    <div class="btn-ver" v-if="home">
                        <div>
                            <router-link to="/indicacoes" class="btn-produto">
                            VER INDICAÇÕES
                            </router-link>
                        </div>

                        <!--<div>
                            <a href="#beneficios" class="btn-cupom" v-if="indicacoes.confirmados == 15">
                                GERAR CUPOM 20% OFF
                            </a>                   
                        </div>-->
                    </div>
                </div>

                <b-modal id="modal-instagram" hide-footer @shown="triggerResize">
                        <carousel :perPageCustom="[[0, 1], [768, 3]]" :navigation-enabled="true" ref="carouselDesktop" >
                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-1.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>BAIXE A IMAGEM DE DIVULGAÇÃO</h3>

                                        <img src="@/assets/img/passos/1.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                          <button type="button" class="btn-entrar" @click="baixarImagem()" >BAIXAR
                                          </button>
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-2.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>ABRA O INSTAGRAM PELO CELULAR</h3>

                                        <img src="@/assets/img/passos/1.5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-3.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>NOS STORIES CLIQUE NO BOTÃO DE ADESIVOS</h3>

                                        <img src="@/assets/img/passos/2.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-4.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>SELECIONE O ADESIVO DE LINK</h3>

                                        <img src="@/assets/img/passos/link3.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-5.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>COLE O SEU LINK PERSONALIZADO</h3>

                                        <img src="@/assets/img/passos/4.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div class="card-passo">
                                        <img src="@/assets/img/passos/item-6.png" class="item-passo" alt="Imagem do passo a passo" />
                                        <h3>AGORA É SÓ POSTAR!</h3>

                                        <img src="@/assets/img/passos/5.png" class="item-passo" alt="Imagem mostrando o passo a passo" />
                                            <button type="button" class="btn-entrar" @click="irInstagram()">IR PARA O INSTAGRAM
                                          </button>
                                     </div>
                                </slide>
                        </carousel>                    
                </b-modal>

            </div>
        </section>

    </div>
</template>

<script>

import { api } from "@/services.js";
import MenuMobile from "@/components/Base/MenuMobile.vue";
export default {
  name: "Indique",
   components: {
    MenuMobile,
  },

  data() {
    return {
        loading: false,
        home: false,
        interna: false,
        indicacoes: [],
        codigos: []
    };
  },
  methods: {
    baixarImagem() {
        /*
      const url = 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/stories_indique.png';
      const link = document.createElement('a');
      link.href = url;
      link.download = 'stories_indique.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);*/

      const url = 'https://emailimagesnb-v2.s3.sa-east-1.amazonaws.com/oink/Stanley/legionarios/stories_indique.png';
      window.open(url, '_blank');
    },
    irInstagram() {
        window.open("https://www.instagram.com", "_blank")
    },

    triggerResize() {
      window.dispatchEvent(new Event('resize'));
    },
    openModal() {
        this.$bvModal.show('modal-instagram');
    },
    copiarTexto() {
      const inputElement = document.querySelector('.copiar input');
      inputElement.select();
      document.execCommand('copy');
      inputElement.focus();
    },

    buscarIndicacoes() {
        this.loading = true;
        api
          .get('/indicacao')
          .then((resp) => {
            this.indicacoes = resp.data;   
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

    buscarCodigo() {
        this.loading = true;
        api
          .get('/indicacao/codigo')
          .then((resp) => {
            this.codigos = resp.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

  },
  mounted() {
    this.buscarIndicacoes();
    this.buscarCodigo();

    if (this.$route.path === "/home") {
      this.home = true;
    } 

    if (this.$route.path === "/indicacoes") {
      this.interna = true;
    } 
  },
};
</script>

<style scoped>

.icone-instagram {
    cursor: pointer;
}

.btn-entrar {
    transition: background-color 0.3s ease;
    background-color: #00abc8;
    color: white;
    font-size: 16px;
    border: none;
    box-shadow: 2px 3px 9px #00000033;
    cursor: pointer;
    padding: 10px 50px;
    margin: 0px auto; 
    display: block;
    text-align: center;
    width: 300px;
    height: 50px;
    margin-top: 20px !important;
  }

  .btn-entrar:hover {
    background-color: #00b6d5;
  }

.card-passo {
    background-color: white;
    padding: 40px 20px !important;
    border-radius: 15px;
    width: 100%;
    min-height: 518px;
}

.indique {
    padding: 40px 0px;
    background-image: url('@/assets/img/desk-home.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.indique-interna {
    padding: 40px 0px;
    background-image: url('@/assets/img/PG_indique.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.selo {
    border: 2px dashed #D4D1C4;
    padding: 5px 20px;
    align-items: center;
    width: 300px;
    height: 85px;
    gap: 8px;
    background: transparent;
}

.selo img {
    width: 45px;
}

.menu-indicacoes {
    margin-bottom: 30px;
    margin-top: -30px;
}

.qtd-ind {
    font-family: "KNOCKOUT-HTF70" !important;
    color: #FF681E !important;
    font-size: 70px !important;
    margin-bottom: 0px !important;
    margin-top: 6px !important;
}

.concluido {
    margin-bottom: 0px !important;
    color: #FBFBFB !important;
    font-family: "KNOCKOUT-HTF70" !important;
    font-size: 27px !important;
    line-height: 30px;
    text-align: center;
}

.icones {
    gap: 15px;
}

.h-titulo {
    letter-spacing: 1.5px;
    font-family: "klavika medium";
    color: #fff;
    line-height: 60px;
    font-size: 55px;
    margin-bottom: 0px;
    font-weight: bold;
}

.h-titulo strong {
    color: #fff !important;
}

.indique .txt-compartilhar, 
.indique-interna .txt-compartilhar {
    font-family: "klavika light";
    color: #fff;
    width: 350px;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
    margin-top: 100px;
    letter-spacing: 1.9px;
}

.indique .txt-compartilhar strong,
.indique-interna .txt-compartilhar strong {
    color: white !important;
}

.copiar {
    width: 700px;
    margin-bottom: 30px !important;
}

.copiar input {
    border-radius: 0px;
    background: #00000087 0% 0% no-repeat padding-box;
    border: 1px solid white;
    font-size: 25px;
    color: white;
    padding: 10px 20px;
}

.copiar .btn {
    letter-spacing: 1px;
    font-size: 22px;
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    border-radius: 0px;
    height: 56px;
    width: 150px;
    background-color: #FF681E;
    border: none;
}

.copiar .btn:hover {
    transform: none;
    background-color: #f05b11;
}

.box-redes {
    width: 700px;
}

.box-redes p {
    letter-spacing: 1.8px;
    font-family: "klavika light";
    color: #FFFFFF;
    font-size: 19px;
}

.redes-sociais {
    gap: 15px;
    align-items: end;
}

.ganhe {
    width: 340px;
    margin-bottom: 30px !important;
}

.footer {
    background-color: #232527;
    padding: 40px 0px;
}

.footer img {
    margin: 0 auto;
    padding-bottom: 20px;
}

.copyright {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0px;
}

.logo-topo {
    width: 150px;
}

.btn-produto {
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    text-decoration: none;
    background-color: #FF681E;
    color: white;
    padding: 15px 60px;
    font-size: 18px;
    letter-spacing: 0.27px;
}

.btn-produto:hover {
    background-color: #f05b11;
}

.btn-cupom {
    font-family: "klavika medium";
    text-decoration: none;
    background-color: white;
    color: #708573;
    padding: 15px 30px;
    font-size: 18px;
    letter-spacing: 0.27px;
}

.btn-cupom:hover {
    background-color: #708573;
    color: white;
}

.redesSociais {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.formato-desk {
    margin-top: 0px !important;
    margin-bottom: 30px !important;
}


@media (min-width: 768px) {
    .vermobile {
        display: none;
    }

    .t-mob {
        display: none;
    }

}

@media (max-width: 767px) {

    .t-desk {
        display: none;
    }

    .ganhe {
        width: 200px;
        margin: initial;
    }

    .verdesktop {
        display: none;
    }

    .card-passo {
        padding: 40px 0px !important;
    }

    .box-redes p {
        text-align: center;
    }

    .indique .txt-compartilhar, 
    .indique-interna .txt-compartilhar {
        font-size: 15px;
        width: 100%;
        line-height: 18px;
    }

    .box-redes, .selo {
        width: 100%;
    }

    .h-titulo {
        font-size: 24px;
        text-align: left;
        line-height: 30px;
        margin-bottom: 5px;
    }

    .txt-compartilhar {
        text-align: left !important;
        line-height: 23px;
        width: 100% !important;
        margin-top: 450px !important;
    }

    .indique, .indique-interna {
        padding: 30px 10px;
    }

    .redesSociais {
        display: grid;
    }

    .copiar {
        width: 100%;
    }

    .copiar input {
        font-size: 15px;
    }

    .copiar .btn {
        font-size: 13px;
        height: 50px;
        width: 90px;
    }

    .indique, .indique-interna {
        background-size: cover;
        background-image: url('@/assets/img/mob-home.png') !important;
    }

    .redes-sociais {
        display: block !important;
    }

    .selo {
        gap: 10px;
    }

    .icones {
        justify-content: center;
        margin-bottom: 15px;
    }

    .btn-ver {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .btn-cupom {
        padding: 15px 10px;
        font-size: 13px;
    }

    .btn-produto {
        width: 100%;
        margin: auto;
        display: table;
        text-align: center;
        padding: 15px 20px;
        font-size: 15px;
    }

}

</style>