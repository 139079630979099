<template>
    <div>
        <div class="loading_global" v-if="loading">
            <span class="loader"></span>
        </div>
       
        <div v-else>
            <section class="start" v-if="!cadastroRedeSocial">
                <!--<div class="video-container">
                    <video autoplay muted loop class="video desk">
                        <source src="@/assets/videos/WIDE_GERAL.mp4" type="video/mp4">
                    </video>

                    <video autoplay muted loop class="video mob" playsinline>
                        <source src="@/assets/videos/GERAL.mp4" type="video/mp4">
                    </video>
                </div>-->

                <!--<div class="container">
                    <div class="informacoes">
                        <img src="@/assets/img/logo_legionarios_branca.svg" alt="Logo Stanley Legionários" class="logo-item"/>
                            
                        <p>Nós não seguimos: desviamos da trilha para navegar até novas descobertas. 
                        No fundo do nosso DNA, acreditamos que a originalidade é o caminho para um futuro melhor.</p>
                        <router-link to="/cadastro" class="btn-personalizar" style="text-decoration: none;" data-anima="bottom">
                            <button class="junte">JUNTE-SE A NÓS</button>
                        </router-link>
                    </div>
                </div>-->

                <div class="topo-menu">
                    <div class="container topo">
                        <div class="topo-mob">
                            <img
                                src="@/assets/img/logo-stanley.png"
                                alt="Logo Stanley Legionários"
                                class="logo-topo"
                            />

                            <router-link to="/login" style="text-decoration: none; color: inherit" data-anima="bottom" class="entrar viewmob">
                                    ENTRAR
                            </router-link>
                        </div>
                        
                        <div class="menu-deslogado">
                            <ul class="item-deslogado">
                                <li>
                                    <a href="#id-beneficios">BENEFÍCIOS</a> 
                                </li>
                                <li>
                                    <a href="#id-colecao">COLEÇÃO</a> 
                                </li>
                                <li>
                                    <a href="#id-duvidas">DÚVIDAS</a> 
                                </li>
                            </ul>
                            <router-link to="/login" style="text-decoration: none; color: inherit" data-anima="bottom" class="entrar viewdesk">
                                ENTRAR
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="container bg-inicio">
                    <b-row>  
                        <b-col cols="12" md="6" class="col-cadastro">
                             <img
                                src="@/assets/img/titulo.png"
                                alt="Logo Stanley Legionários"
                                class="titulo-banner viewdesk"
                            />
                             <img
                                src="@/assets/img/t-mob.png"
                                alt="Logo Stanley Legionários"
                                class="titulo-banner viewmob"
                            />
                            <router-link to="/cadastro" style="text-decoration: none; color: inherit" data-anima="bottom" class="fazer-cadastro" >
                                FAZER CADASTRO
                            </router-link>
                         </b-col>
                    </b-row>
                </div>

                <p class="txt-sobre-leg container">Legionários é um hub de relacionamento exclusivo para clientes no Brasil, oferecendo diversos benefícios</p>
                
                <div class="secao-video" id="id-beneficios">
                    <video autoplay muted loop class="video-beneficios viewdesk">
                        <source src="@/assets/videos/DESK_LOGIN.mp4" type="video/mp4" >
                    </video>

                    <video autoplay muted loop class="video-beneficios viewmob">
                        <source src="@/assets/videos/MOB_LOGIN.mp4" type="video/mp4">
                    </video>

                    <div class="container">
                        <div class="informacoes">                               
                            <h3>BENEFÍCIOS</h3>
                            <h4>SÓ PRA QUEM É DA LEGIÃO</h4>
                            <p>Complete missões e desbloqueie benefícios exclusivos!</p>
                        </div>
                        <div class="botao-final">
                            <router-link to="/cadastro" class="btn-fazer" style="text-decoration: none;" data-anima="bottom" >
                                FAZER CADASTRO
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="viewmob caroselMobile container">
                    <img src="@/assets/img/banners/banner-mob-1.png" alt="Imagem do banner"/>
                    <img src="@/assets/img/banners/banner-mob-2.png" alt="Imagem do banner"/>
                    <img src="@/assets/img/banners/banner-mob-3.png" alt="Imagem do banner"/>
                    <img src="@/assets/img/banners/banner-mob-4.png" alt="Imagem do banner"/>
                    <img src="@/assets/img/banners/banner-mob-5.png" alt="Imagem do banner" />
                </div>

                <div class="secao-banners container viewdesk">
                    <carousel :perPage="1" :navigation-enabled="true" ref="Banners" >
                                <slide class="p-2">
                                    <div>
                                        <img src="@/assets/img/banners/banner-1.png" alt="Imagem do banner" class="viewdesk"/>
                                     </div>
                                </slide>
                                
                                <slide class="p-2" >
                                    <div>
                                        <img src="@/assets/img/banners/banner-2.png" alt="Imagem do banner"  class="viewdesk"/>   
                                     </div>
                                </slide>

                                 <slide class="p-2" >
                                    <div>
                                        <img src="@/assets/img/banners/banner-4.png" alt="Imagem do banner"  class="viewdesk"/>                
                                     </div>
                                </slide>

                                 <slide class="p-2" >
                                    <div>
                                        <img src="@/assets/img/banners/banner-5.png" alt="Imagem do banner" class="viewdesk"/>                                     
                                     </div>
                                </slide>

                                <slide class="p-2" >
                                    <div>
                                        <img src="@/assets/img/banners/banner-6.png" alt="Imagem do banner" class="viewdesk" />
                                        
                                     </div>
                                </slide>
                        </carousel>  
                </div>

                <div class="minha-colecao" id="id-colecao">
                    <img
                        src="@/assets/img/bgcolecao.png"
                        alt="minha coleção"
                        class="viewdesk"
                    />
                    <img
                        src="@/assets/img/img-mobile.png"
                        alt="minha coleção"
                        class="viewmob"
                    />

                    <b-container>
                        <b-row class="row-txt">  
                            <b-col cols="12" md="7">
                                <p class="t-colecao">Os produtos Stanley que você compra no site aparecem automaticamente e você também pode adicionar 
                                    aqueles adquiridos em outros locais ou que recebeu de presente.</p>
                            </b-col>
                            <b-col cols="12" md="5">
                                <div class="col-junte">
                                    <router-link to="/login" style="text-decoration: none; color: inherit" data-anima="bottom" class="entrar juntese">
                                        JUNTE-SE A NÓS
                                    </router-link>
                                </div> 
                            </b-col>
                        </b-row>
                    </b-container>
                    
                </div>
            </section>

            <section v-else class="container">

                <div class="loading_global" v-if="loading">
                <span class="loader"></span>
                </div>

                <div class="formulario">
                        <img
                            src="@/assets/img/logo_legionarios_preto.svg"
                            alt="Logo Stanley Legionários"
                            class="logo"
                        />
                    <div class="titulo-perfil">
                        <h2>COMPLETE <strong>SEU CADASTRO</strong></h2>
                    </div>

                    <form @submit.prevent="editarPerfil" v-if="!loading">
                        
                        <label for="cpf">CPF</label>
                            <the-mask
                                type="text"
                                id="cpf"
                                name="cpf"
                                v-model="usuarioLogado.cpf"
                                :mask="'###.###.###-##'"
                                :masked="false"
                                inputmode="numeric"
                                required
                                />

                            <label for="celular">Celular</label>
                            <the-mask
                                type="text"
                                id="celular"
                                name="celular"
                                v-model="usuarioLogado.celular"
                                :mask="'(##) #####-####'"
                                inputmode="numeric"
                                required
                                :masked="false"
                                /> 

                                <label for="data">Data de nascimento:</label>
                                <input type="date" id="data" name="data" v-model="usuarioLogado.dataNascimento" required><br><br>
                        
                        <div class="box-atualizar">
                            <button type="submit" class="btn-avancar margin-btn">FINALIZAR</button>
                        </div>
                    </form>  
                <TheFooter />
                </div>

            </section>

            <section class="sucesso" v-if="sucesso">
                <img
                        src="@/assets/img/logo_legionarios_branca.svg"
                        alt="Logo Stanley Legionários"
                        class="logo"
                />

                <div class="conteudo-sucesso" data-anima="bottom">
                <h1>PERFIL CADASTRADO COM <strong>SUCESSO!</strong></h1>

                <router-link to="/home" style="text-decoration: none;" >
                    <button type="button" class="btn-login">IR PARA A HOME</button>
                </router-link>
                </div>
            </section>
            
            <div id="id-duvidas">
                <FaqPortalDeslogado />
            </div>

            <TheFooter />
        </div>
    </div>
</template>

<script>
import FaqPortalDeslogado from "@/components/FaqPortalDeslogado.vue";
import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services.js";
export default {
 name: "Start",
 components: {
  TheFooter,
  FaqPortalDeslogado
  },
 data() {
    return {
      dadosGoogle: [],
      loading: false,
      token: "",
      tokenFace: "",
      dadosFace: [],
      cadastroRedeSocial: false,
      sucesso: false,
      usuarioLogado: []
    };
  },
  mounted() {

    this.getHashAndPhone()

    if (window.localStorage.token) {
        this.$router.push({ path: "/home"});
    } 

    const rParam = this.$route.query.r;
    this.$store.commit('SET_R_PARAM', rParam);

    const hashParams = window.location.hash.substring(1);
    const params = new URLSearchParams(hashParams);
    
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');
    const tokenFace = params.get('long_lived_token');


    if(idToken) {
        this.getUserInfo(accessToken, idToken);
    } else if (accessToken) {
        this.getUserInfoFace(accessToken);
    } else {
        this.getUserInfoFace(tokenFace);
    }
  },
  methods: {
    getHashAndPhone() {
      console.log('hm')
      const urlParams = new URLSearchParams(window.location.search);
      const hash = urlParams.get('hash');  
      const code = urlParams.get('code');  
      console.log('hash', hash, 'code', code)
    },
      getUserInfo(accessToken, idToken) {
        this.loading = true;
        fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
        })
        .then(response => response.json())
        .then(data => {
            this.dadosGoogle = data;
            this.provider = "google";
            const dataToSend = {
                    email: this.dadosGoogle.email,
                    provider: this.provider,
                    token: idToken,
                    nome: this.dadosGoogle.given_name
            };

            this.enviarDados(dataToSend);

        })
        .catch(error => {
            console.error('Erro ao obter informações do usuário:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getUserInfoFace(TokenFace) {
        
        this.loading = true;
        fetch(`https://graph.facebook.com/v19.0/me?fields=name,email,picture&access_token=${TokenFace}`)
            .then(response => response.json())
            .then(data => {
                this.dadosFace = data;
                this.provider = "facebook";

                const dataFace = {
                    email: this.dadosFace.email,
                    provider: this.provider,
                    token: TokenFace,
                    nome: this.dadosFace.name,
                };
                this.enviarDadosFace(dataFace);
                })

            .catch(error => {
                console.error('Erro ao obter informações do usuário:', error);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    enviarDados(data) {
        this.loading = true;
        api
                .post(`/auth/social-login`, data)
                    .then((response) => {
                        this.token = response.data.token.token;
                        window.localStorage.token = this.token;
                        this.$router.push("/novo-registro");
                })
                .catch((error) => {
                    console.error(error);
                })
                 .finally(() => {
                    this.loading = false;
                });
    },

    enviarDadosFace(dataFace) {
        this.loading = true;
        api
                .post(`/auth/social-login`, dataFace)
                    .then((response) => {
                        
                        this.tokenFace = response.data.token.token;
                        window.localStorage.token = this.tokenFace;
                        this.$router.push("/novo-registro");
                })
                .catch((error) => {
                    console.error(error);
                })
                 .finally(() => {
                    this.loading = false;
                });
    },

  },

};
</script>

<style scoped>
.start {
    background-color: #fff;
}

.bg-inicio {
  background-image: url('@/assets/img/headerstart.png') !important;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}
/*
.topo-menu {
    position: absolute;
    top: 0;
    width: 100% !important;
}*/

.col-cadastro {
    padding-bottom: 80px;
}

.secao-banners img {
    width: 100% !important;
}

.topo {
    align-items: center;
    padding: 30px 0px;
    display: flex;
}

.txt-sobre-leg {
    color: #222426;
    font-size: 22px;
    font-family: "klavika medium";
    text-align: center;
    margin-top: 23px;
    margin-bottom: 25px;
}

.secao-banners {
    margin-top: 20px;
    margin-bottom: 40px;
}

.titulo-banner {
    width: 500px;
    margin-left: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
}

.logo-item {
    width: 560px;
}

.video-beneficios {
    width: 100%;
    height: auto;
}

.btn-fazer {
    box-shadow: 2px 3px 9px #00000033;
    border: none;
    font-family: "klavika medium";
    background-color: #FF681E;
    text-align: center;
    color: white !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: 16px;
    text-decoration: none;
    padding: 17px 80px;
}

.fazer-cadastro {
    box-shadow: 2px 3px 9px #00000033;
    border: none;
    font-family: "klavika medium";
    background-color: #FF681E;
    text-align: center;
    color: white !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 200;
    letter-spacing: 1px;
    font-size: 16px;
    text-decoration: none;
    padding: 15px 60px;
    margin-left: 40px;
}

.start .entrar {
    box-shadow: 2px 3px 9px #00000033;
    border: none;
    font-family: "klavika medium";
    background-color: #FF681E;
    width: 150px;
    text-align: center;
    color: white !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: 200;
    letter-spacing: 0.24px;
    font-size: 16px;
    text-decoration: none;
    padding: 5px;
}

.start .entrar:hover, 
.fazer-cadastro:hover,
.btn-fazer:hover {
  background-color: #f05b11;
}

.junte {
    width: 370px !important;
    height: 67px !important;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100vh; 
}

.secao-video {
    position: relative !important;
}

.t-colecao {
    font-family: "klavika light";
    line-height: 33px;
    color: #222426;
    font-size: 27px;
}

.informacoes h3 {
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    color: #FFFFFF;
    font-size: 70px;
    margin-top: 80px;
    margin-bottom: 0px;
}

.informacoes h4 {
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    color: #FFFFFF;
    font-size: 31px;
}

.informacoes p {
    font-family: "klavika medium";
    font-size: 20px;
    color: #fff;
}

.informacoes {
    top: 0px;
    position: absolute !important;
 }

 .botao-final {
    bottom: 150px;
    position: absolute !important;
 }

.logo-footer {
    width: 150px;
}

.footer {
    background-color: #232527;
    padding: 40px 0px;
}

.footer img {
    margin: 0 auto;
    padding-bottom: 20px;
}

.copyright {
    color: #fff;
}

.menu-deslogado {
    gap: 40px;
    display: flex;
    align-items: center;
}

.item-deslogado {
    margin-bottom: 0px;
    gap: 40px;
    display: flex;
}

.item-deslogado a {
    transition: background-color 0.3s ease;
    text-decoration: none;
    font-family: "KNOCKOUT-HTF53-LITEWEIGHT";
    font-size: 18px;
    color: #222426;
}

.item-deslogado a:hover {
    color: #f05b11;
}

.logo-topo {
    width: 160px;
}

.row-txt {
    margin-top: 30px;
    margin-bottom: 30px;
}

.juntese {
    width: 250px !important;
    padding: 10px 0px !important;
}

.col-junte {
    justify-content: end;
    display: flex;
    margin-top: 50px;
}

.caroselMobile {
    margin-bottom: 20px;
    overflow: auto;
    display: flex;
}

@media (min-width: 990px) { 
    .mob, .viewmob {
      display: none;
    }

    video.desk {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }

    .topo {
        justify-content: space-between;
    }
}

 @media (max-width: 989px) { 

    .topo-mob {
        align-items: center;
        justify-content: space-between;
        display: flex;
    }

    .start .entrar {
        padding: 2px;
    }

    .item-deslogado {
        margin-top: 18px;
        justify-content: space-between;
        gap: 30px;
        padding-left: 0px !important;
    }

    .menu-deslogado {
        display: grid !important;
    }

    .item-deslogado a {
        font-size: 13px;
    }

    .btn-fazer {
        font-size: 15px;
        padding: 12px 70px;
        margin-left: 10px;
    }

    .botao-final {
        bottom: 60px;
    }

    .informacoes h3 {
        font-size: 45px;
        margin-top: 14px;
        margin-bottom: 0px;
    }

    .txt-sobre-leg {
        line-height: 27px;
        text-align: left;
    }

    .informacoes h4 {
        font-size: 34px;
        line-height: 35px;
    }

    .video::-webkit-media-controls {
        display: none !important;
    }

    .video::-webkit-media-controls-enclosure {
        display: none !important;
    }

    .video::-webkit-media-controls-panel {
        display: none !important;
    }

    .logo-topo {
        height: auto;
        width: 140px !important;
    }

    .informacoes h1 {
      font-size: 60px;
      letter-spacing: 2px;
    }

    .informacoes p {
        font-size: 18px;
        margin-bottom: 15px;
        width: 100%;
        margin-top: 20px;
        line-height: 23px;
    }

    .video.mob {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }

    .desk, .viewdesk {
      display: none;
    }

    .topo {
        padding: 15px 15px 15px 15px;
        display: grid !important;
    }

    .logo-item, .junte {
        width: 100% !important;
    }

    .entrar {
        width: 120px !important;
    }

    .juntese {
        width: 100%  !important;
    }

    .col-junte {
        margin-top: 5px;
    }

    .t-colecao {
        line-height: 27px;
        font-size: 20px;
    }

    .informacoes {
        padding: 0px 25px 0px 10px;
        bottom: 60px;
    }

    .bg-inicio {
        background-image: url('@/assets/img/headerstartmob.png') !important;
        height: 500px;
    }

    .titulo-banner {
        width: 100%;
        margin-left: 0px; 
        padding-top: 35px;
        padding-bottom: 0px;
    }

    .fazer-cadastro {
        padding: 12px 90px;
        margin-left: 0px; 
        margin: auto;
        display: table;
        margin-top: 215px;
    }

}

@media (min-width: 991px) and (max-width: 1199px) { 
    .titulo-banner {
        margin-bottom: 60px !important;
        padding-top: 60px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) { 
    .titulo-banner {
        padding-top: 60px;
    }
}
</style>