<template>

  <section class="login" data-anima="bottom">
    <div class="loading_global" v-if="loading">
      <span class="loader"></span>
    </div>
    <div>
      <router-link to="/"><img src="@/assets/img/logo_legionarios_preto.svg" alt="Logo Stanley Legionários"
          class="logo" /></router-link>
      <h1><strong class="strong">BOM TE VER</strong> POR AQUI!</h1>

      <div class="form-login">
        <Notificacao :infos="notificacao_infos" v-if="notificacao_infos.mensagem" />
        <form @submit.prevent="SubmitLogin">
          <label for="email">Endereço de e-mail</label>

          <input type="email" id="email" name="email" v-model="login.email" required><br><br>
          <label for="senha">Senha</label>
          <div class="campo-senha">
            <input :type="mostrarSenha ? 'text' : 'password'" id="senha" name="senha" v-model="login.senha"
              ref="senhaInput" required><br><br>
            <div @click.prevent="toggleMostrarSenha" class="btn-view">
              <img v-if="mostrarSenha" src="@/assets/img/noview.png" alt="Icone de visualização" class="view" />
              <img v-else src="@/assets/img/view.png" alt="Icone de visualização" class="view" />
            </div>
          </div>
          <input type="submit" value="ENTRAR" class="btn-entrar">
        </form>

        <div class="link">
          <router-link to="/esqueceu-a-senha">
            Esqueci a senha
          </router-link>
        </div>

        <!--<div class="box-linhas">
                    <div class="linha"></div>
                    <div><p class="txt">ou</p></div>
                    <div class="linha"></div>
                </div>

                <BotaoLogin />-->

        <p class="txtcadastro">Ainda não tem conta?
          <strong>
            <router-link to="/cadastro">Cadastre-se já</router-link>
          </strong>
        </p>



      </div>
      <TheFooter />
    </div>


     <!-- <FilaDeEspera v-else
      @logando="verificarFila"
      />-->


  </section>

</template>

<script>

import TheFooter from "@/components/Base/TheFooter.vue";
import { api } from "@/services";
//import FilaDeEspera from "./FilaDeEspera.vue";

export default {
  name: "Login",
  components: {
    TheFooter,
    /*FilaDeEspera*/
  },
  data() {
    return {
      loginAutorizadoFila: true,
      notificacao_infos: {
        variant: "info",
        mensagem: "",
        count_timer: 0,
      },
      mostrarSenha: false,
      loading: false,
      login: {
        email: '',
        senha: ''
      },
      emailURL: "",
      decodedEmail: ""
    };
  },
  methods: {
    verificarFila(){
      this.loginAutorizadoFila = true
    },
    toggleMostrarSenha() {
      this.mostrarSenha = !this.mostrarSenha;
    },

    SubmitLogin() {
      this.loading = true;
      this.$store
        .dispatch("logarUsuario", this.login)
        .then(() => {
          setTimeout(() => {
            this.$router.push({
              path: "/home",
              query: this.$route.query,
            });
          }, 200);
        })
        .catch((error) => {
          console.log(error);
          this.notificacao_infos = {
            variant: "error",
            mensagem: error.response
              ? error.response.data.errors[0]?.message
              : "Algo deu errado na requisição",
            count_timer: 10,
          };
          this.loading = false;

        });
    },
    deslogarUsuario() {
      this.$store.dispatch('deslogarUsuario');
    },
    getHashAndPhone() {
      const urlParams = new URLSearchParams(window.location.search);
      const hash = urlParams.get('hash');  
      const code = urlParams.get('code');  

      if(hash && code){

        console.log(hash,this.decrypt(code) )

        const dados = {
          celular: this.decrypt(code),
          hash: hash,
        }

        api
        .post('auth/loginbywill', dados)
        .then((resp)=>{
          window.localStorage.token = resp.data.token
          setTimeout(() => {
            this.$router.replace({
                path: "/home",
            });          }, 200);
        })
        .catch((error)=>{
          console.log(error)
          setTimeout(() => {
            this.$router.replace({
                path: "/login",
            });          }, 200);
        })

      }


    },
    decrypt(input) {
    if (input.startsWith("LCL") && input.endsWith("TM")) {
        input = input.substring(3, input.length - 2); 
    }

    let decrypted = input.split("").map(char => {
        if (char.toUpperCase() === "X") return "0"; 
        let value = char.toUpperCase().charCodeAt(0) - 64;
        return value >= 1 && value <= 26 ? value : char; 
    }).join("");

    return decrypted;
}


  },
  mounted() {
    this.getHashAndPhone()
    this.emailURL = this.$route.query.e;
    if (this.emailURL) {
      this.login.email = atob(this.emailURL);
      this.$refs.senhaInput.focus();
    }

    if (window.localStorage.token) {
      this.$router.push({ path: "/home" });
    }
  },

};
</script>

<style scoped>
.view {
  margin-top: 15px;
}

.txtcadastro {
  color: #52575A;
  font-size: 16px;
  text-align: center;
  margin: 40px 0px;
}

.btn-entrar {
  margin-top: 20px;
}

@media (max-width: 990px) {
  .login .logo {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .login h1 {
    margin: 20px 0px;
  }

  .txtcadastro {
    margin: 30px 0px;
  }

  .copyright {
    margin-bottom: 30px;
  }
}
</style>